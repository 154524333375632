export const ceramicPhotos = [
    {
        src: '/images/gallery/allegro0199L.jpg',
        srcSet: [
            '/images/gallery/allegro0199s.jpg 80w',
            '/images/gallery/allegro0199m.jpg 248w',
            '/images/gallery/allegro0199L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "orange seaside party vase"
    },
    {
        src: '/images/gallery/allegro0359L.jpg',
        srcSet: [
            '/images/gallery/allegro0359s.jpg 80w',
            '/images/gallery/allegro0359m.jpg 248w',
            '/images/gallery/allegro0359L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "small green incense burner"
    },
    {
        src: '/images/gallery/allegro0201L.jpg',
        srcSet: [
            '/images/gallery/allegro0201s.jpg 80w',
            '/images/gallery/allegro0201m.jpg 248w',
            '/images/gallery/allegro0201L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "kumquat vase"
    },
    {
        src: '/images/gallery/allegro0203L.jpg',
        srcSet: [
            '/images/gallery/allegro0203s.jpg 80w',
            '/images/gallery/allegro0203m.jpg 248w',
            '/images/gallery/allegro0203L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "spotted jungle vase"
    },
    {
        src: '/images/gallery/oceania0205L.jpg',
        srcSet: [
            '/images/gallery/oceania0205s.jpg 80w',
            '/images/gallery/oceania0205m.jpg 248w',
            '/images/gallery/oceania0205L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "ocean bottle with star lid"
    },
    {
        src: '/images/gallery/roma0423L.jpg',
        srcSet: [
            '/images/gallery/roma0423s.jpg 80w',
            '/images/gallery/roma0423m.jpg 248w',
            '/images/gallery/roma0423L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "roman galley bottle"
    },
    {
        src: '/images/gallery/roma0541L.jpg',
        srcSet: [
            '/images/gallery/roma0541s.jpg 80w',
            '/images/gallery/roma0541m.jpg 248w',
            '/images/gallery/roma0541L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "roman bowl with spirals"
    },
    {
        src: '/images/gallery/allegro0212L.jpg',
        srcSet: [
            '/images/gallery/allegro0212s.jpg 80w',
            '/images/gallery/allegro0212m.jpg 248w',
            '/images/gallery/allegro0212L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "white opus bottle"
    },
    {
        src: '/images/gallery/allegro0214L.jpg',
        srcSet: [
            '/images/gallery/allegro0214s.jpg 80w',
            '/images/gallery/allegro0214m.jpg 248w',
            '/images/gallery/allegro0214L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "white forest vase - SOLD"
    },
    {
        src: '/images/gallery/allegro0247L.jpg',
        srcSet: [
            '/images/gallery/allegro0247s.jpg 107w',
            '/images/gallery/allegro0247m.jpg 331w',
            '/images/gallery/allegro0247L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "allegro jar and vases"
    },
    {
        src: '/images/gallery/allegro0216L.jpg',
        srcSet: [
            '/images/gallery/allegro0216s.jpg 80w',
            '/images/gallery/allegro0216m.jpg 248w',
            '/images/gallery/allegro0216L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "zebra bottle"
    },
    {
        src: '/images/gallery/allegro0223L.jpg',
        srcSet: [
            '/images/gallery/allegro0223S.jpg 80w',
            '/images/gallery/allegro0223m.jpg 248w',
            '/images/gallery/allegro0223L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "blue vase with brown spots"
    },
    {
        src: '/images/gallery/allegro0256L.jpg',
        srcSet: [
            '/images/gallery/allegro0256s.jpg 80w',
            '/images/gallery/allegro0256m.jpg 248w',
            '/images/gallery/allegro0256L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "giraffe vase"
    },
    {
        src: '/images/gallery/allegro0259L.jpg',
        srcSet: [
            '/images/gallery/allegro0259s.jpg 80w',
            '/images/gallery/allegro0259m.jpg 248w',
            '/images/gallery/allegro0259L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "textured black vase - SOLD"
    },
    {
        src: '/images/gallery/allegro0265L.jpg',
        srcSet: [
            '/images/gallery/allegro0265s.jpg 80w',
            '/images/gallery/allegro0265m.jpg 248w',
            '/images/gallery/allegro0265L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "striped honey pot"
    },
    {
        src: '/images/gallery/allegro0351L.jpg',
        srcSet: [
            '/images/gallery/allegro0351s.jpg 80w',
            '/images/gallery/allegro0351m.jpg 248w',
            '/images/gallery/allegro0351L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "small white bud vase"
    },
    {
        src: '/images/gallery/allegro0354L.jpg',
        srcSet: [
            '/images/gallery/allegro0354s.jpg 107w',
            '/images/gallery/allegro0354m.jpg 331w',
            '/images/gallery/allegro0354L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "woodland jars"
    },
    {
        src: '/images/gallery/allegro0381L.jpg',
        srcSet: [
            '/images/gallery/allegro0381s.jpg 107w',
            '/images/gallery/allegro0381m.jpg 331w',
            '/images/gallery/allegro0381L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "zebra saucer"
    },
    {
        src: '/images/gallery/allegro0383L.jpg',
        srcSet: [
            '/images/gallery/allegro0383s.jpg 107w',
            '/images/gallery/allegro0383m.jpg 331w',
            '/images/gallery/allegro0383L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "sushi tray with polka dots"
    },
    {
        src: '/images/gallery/allegro0386L.jpg',
        srcSet: [
            '/images/gallery/allegro0386s.jpg 107w',
            '/images/gallery/allegro0386m.jpg 331w',
            '/images/gallery/allegro0386L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "striped sushi platter"
    },
    {
        src: '/images/gallery/allegro0453L.jpg',
        srcSet: [
            '/images/gallery/allegro0453s.jpg 80w',
            '/images/gallery/allegro0453m.jpg 248w',
            '/images/gallery/allegro0453L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "bovine vase"
    },
    {
        src: '/images/gallery/allegro0454L.jpg',
        srcSet: [
            '/images/gallery/allegro0454s.jpg 80w',
            '/images/gallery/allegro0454m.jpg 248w',
            '/images/gallery/allegro0454L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "black and green bubble bottle"
    },
    {
        src: '/images/gallery/allegro0455L.jpg',
        srcSet: [
            '/images/gallery/allegro0455s.jpg 80w',
            '/images/gallery/allegro0455m.jpg 248w',
            '/images/gallery/allegro0455L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "black jellybean vase"
    },
    {
        src: '/images/gallery/allegro0456L.jpg',
        srcSet: [
            '/images/gallery/allegro0456s.jpg 80w',
            '/images/gallery/allegro0456m.jpg 248w',
            '/images/gallery/allegro0456L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "orange squid bottle"
    },
    {
        src: '/images/gallery/allegro0493L.jpg',
        srcSet: [
            '/images/gallery/allegro0493s.jpg 80w',
            '/images/gallery/allegro0493m.jpg 248w',
            '/images/gallery/allegro0493L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "pink paraffin bottle"
    },
    {
        src: '/images/gallery/allegro0238L.jpg',
        srcSet: [
            '/images/gallery/allegro0238s.jpg 107w',
            '/images/gallery/allegro0238m.jpg 331w',
            '/images/gallery/allegro0238L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "allegro bottle and vases"
    },
    {
        src: '/images/gallery/allegro0510L.jpg',
        srcSet: [
            '/images/gallery/allegro0510s.jpg 107w',
            '/images/gallery/allegro0510m.jpg 331w',
            '/images/gallery/allegro0510L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "green party cups"
    },
    {
        src: '/images/gallery/allegro0515L.jpg',
        srcSet: [
            '/images/gallery/allegro0515s.jpg 80w',
            '/images/gallery/allegro0515m.jpg 248w',
            '/images/gallery/allegro0515L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "little party tray"
    },
    {
        src: '/images/gallery/allegro0517L.jpg',
        srcSet: [
            '/images/gallery/allegro0517s.jpg 107w',
            '/images/gallery/allegro0517m.jpg 331w',
            '/images/gallery/allegro0517L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "little chopstick tray"
    },
    {
        src: '/images/gallery/allegro0520L.jpg',
        srcSet: [
            '/images/gallery/allegro0520s.jpg 107w',
            '/images/gallery/allegro0520m.jpg 331w',
            '/images/gallery/allegro0520L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "funky serving tray - SOLD"
    },
    {
        src: '/images/gallery/allegro0506L.jpg',
        srcSet: [
            '/images/gallery/allegro0506s.jpg 107w',
            '/images/gallery/allegro0506m.jpg 331w',
            '/images/gallery/allegro0506L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "party cups in funky serving tray"
    },
    {
        src: '/images/gallery/allegro0531L.jpg',
        srcSet: [
            '/images/gallery/allegro0531s.jpg 107w',
            '/images/gallery/allegro0531m.jpg 331w',
            '/images/gallery/allegro0531L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "trilobite party tray"
    },
    {
        src: '/images/gallery/allegro0537L.jpg',
        srcSet: [
            '/images/gallery/allegro0537s.jpg 107w',
            '/images/gallery/allegro0537m.jpg 331w',
            '/images/gallery/allegro0537L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "coral goblets"
    },
    {
        src: '/images/gallery/fuji0211L.jpg',
        srcSet: [
            '/images/gallery/fuji0211s.jpg 80w',
            '/images/gallery/fuji0211m.jpg 248w',
            '/images/gallery/fuji0211L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "black bamboo bottle"
    },
    {
        src: '/images/gallery/fuji0364L.jpg',
        srcSet: [
            '/images/gallery/fuji0364s.jpg 80w',
            '/images/gallery/fuji0364m.jpg 248w',
            '/images/gallery/fuji0364L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "fuji box"
    },
    {
        src: '/images/gallery/fuji0366L.jpg',
        srcSet: [
            '/images/gallery/fuji0366s.jpg 80w',
            '/images/gallery/fuji0366m.jpg 248w',
            '/images/gallery/fuji0366L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "haiku box - SOLD"
    },
    {
        src: '/images/gallery/allegro0505L.jpg',
        srcSet: [
            '/images/gallery/allegro0505s.jpg 107w',
            '/images/gallery/allegro0505m.jpg 331w',
            '/images/gallery/allegro0505L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "party cups on trilobite tray"
    },
    {
        src: '/images/gallery/fuji0368L.jpg',
        srcSet: [
            '/images/gallery/fuji0368s.jpg 107w',
            '/images/gallery/fuji0368m.jpg 331w',
            '/images/gallery/fuji0368L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "brown tea bowl"
    },
    {
        src: '/images/gallery/fuji0372L.jpg',
        srcSet: [
            '/images/gallery/fuji0372s.jpg 80w',
            '/images/gallery/fuji0372m.jpg 248w',
            '/images/gallery/fuji0372L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "sculptural rope vase"
    },
    {
        src: '/images/gallery/allegro0245L.jpg',
        srcSet: [
            '/images/gallery/allegro0245s.jpg 107w',
            '/images/gallery/allegro0245m.jpg 331w',
            '/images/gallery/allegro0245L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "allegro vases"
    },
    {
        src: '/images/gallery/fuji0382L.jpg',
        srcSet: [
            '/images/gallery/fuji0382s.jpg 80w',
            '/images/gallery/fuji0382m.jpg 248w',
            '/images/gallery/fuji0382L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "happy vase"
    },
    {
        src: '/images/gallery/fuji0411L.jpg',
        srcSet: [
            '/images/gallery/fuji0411s.jpg 107w',
            '/images/gallery/fuji0411m.jpg 331w',
            '/images/gallery/fuji0411L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "lava bowl"
    },
    {
        src: '/images/gallery/fuji0451L.jpg',
        srcSet: [
            '/images/gallery/fuji0451s.jpg 80w',
            '/images/gallery/fuji0451m.jpg 248w',
            '/images/gallery/fuji0451L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "happy island bowl"
    },
    {
        src: '/images/gallery/fuji0472L.jpg',
        srcSet: [
            '/images/gallery/fuji0472s.jpg 80w',
            '/images/gallery/fuji0472m.jpg 248w',
            '/images/gallery/fuji0472L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "sculptural kyoto vase"
    },
    {
        src: '/images/gallery/fuji0480L.jpg',
        srcSet: [
            '/images/gallery/fuji0480s.jpg 107w',
            '/images/gallery/fuji0480m.jpg 331w',
            '/images/gallery/fuji0480L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "toad-skin bowl"
    },
    {
        src: '/images/gallery/fuji0483L.jpg',
        srcSet: [
            '/images/gallery/fuji0483s.jpg 107w',
            '/images/gallery/fuji0483m.jpg 331w',
            '/images/gallery/fuji0483L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "teal tea bowl"
    },
    {
        src: '/images/gallery/fuji0491L.jpg',
        srcSet: [
            '/images/gallery/fuji0491s.jpg 80w',
            '/images/gallery/fuji0491m.jpg 248w',
            '/images/gallery/fuji0491L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "lascaux vase - SOLD"
    },
    {
        src: '/images/gallery/fuji0529L.jpg',
        srcSet: [
            '/images/gallery/fuji0529s.jpg 80w',
            '/images/gallery/fuji0529m.jpg 248w',
            '/images/gallery/fuji0529L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "pagoda boxes"
    },
    {
        src: '/images/gallery/fuji0545L.jpg',
        srcSet: [
            '/images/gallery/fuji0545s.jpg 107w',
            '/images/gallery/fuji0545m.jpg 331w',
            '/images/gallery/fuji0545L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "tibetan offering bowl"
    },
    {
        src: '/images/gallery/oceania0208L.jpg',
        srcSet: [
            '/images/gallery/oceania0208s.jpg 80w',
            '/images/gallery/oceania0208m.jpg 248w',
            '/images/gallery/oceania0208L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "ocean bottle with spirals"
    },
    {
        src: '/images/gallery/oceania0226L.jpg',
        srcSet: [
            '/images/gallery/oceania0226s.jpg 80w',
            '/images/gallery/oceania0226m.jpg 248w',
            '/images/gallery/oceania0226L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "crustaceous candlesticks"
    },
    {
        src: '/images/gallery/oceania0231L.jpg',
        srcSet: [
            '/images/gallery/oceania0231s.jpg 80w',
            '/images/gallery/oceania0231m.jpg 248w',
            '/images/gallery/oceania0231L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "purple and black urchin vase"
    },
    {
        src: '/images/gallery/oceania0232L.jpg',
        srcSet: [
            '/images/gallery/oceania0232s.jpg 80w',
            '/images/gallery/oceania0232m.jpg 248w',
            '/images/gallery/oceania0232L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "pineapple jar with lid"
    },
    {
        src: '/images/gallery/oceania0236L.jpg',
        srcSet: [
            '/images/gallery/oceania0236s.jpg 80w',
            '/images/gallery/oceania0236m.jpg 248w',
            '/images/gallery/oceania0236L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "black and white starry vase"
    },
    {
        src: '/images/gallery/oceania0313L.jpg',
        srcSet: [
            '/images/gallery/oceania0313s.jpg 107w',
            '/images/gallery/oceania0313m.jpg 331w',
            '/images/gallery/oceania0313L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "seaweed bowl"
    },
    {
        src: '/images/gallery/oceania0315L.jpg',
        srcSet: [
            '/images/gallery/oceania0315s.jpg 107w',
            '/images/gallery/oceania0315m.jpg 331w',
            '/images/gallery/oceania0315L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "black bowl with concentric circles"
    },
    {
        src: '/images/gallery/oceania0317L.jpg',
        srcSet: [
            '/images/gallery/oceania0317s.jpg 107w',
            '/images/gallery/oceania0317m.jpg 331w',
            '/images/gallery/oceania0317L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "shark fin bowl"
    },
    {
        src: '/images/gallery/oceania0397L.jpg',
        srcSet: [
            '/images/gallery/oceania0397s.jpg 107w',
            '/images/gallery/oceania0397m.jpg 331w',
            '/images/gallery/oceania0397L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "trilobite cups"
    },
    {
        src: '/images/gallery/roma0357L.jpg',
        srcSet: [
            '/images/gallery/roma0357s.jpg 80w',
            '/images/gallery/roma0357m.jpg 248w',
            '/images/gallery/roma0357L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "purple stovepipe bottle"
    },
    {
        src: '/images/gallery/roma0358L.jpg',
        srcSet: [
            '/images/gallery/roma0358s.jpg 80w',
            '/images/gallery/roma0358m.jpg 248w',
            '/images/gallery/roma0358L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "small white incense box"
    },
    {
        src: '/images/gallery/roma0392L.jpg',
        srcSet: [
            '/images/gallery/roma0392s.jpg 107w',
            '/images/gallery/roma0392m.jpg 331w',
            '/images/gallery/roma0392L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "savage roman plate"
    },
    {
        src: '/images/gallery/roma0396L.jpg',
        srcSet: [
            '/images/gallery/roma0396s.jpg 80w',
            '/images/gallery/roma0396m.jpg 248w',
            '/images/gallery/roma0396L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "savage roman plate"
    },
    {
        src: '/images/gallery/roma0422L.jpg',
        srcSet: [
            '/images/gallery/roma0422s.jpg 80w',
            '/images/gallery/roma0422m.jpg 248w',
            '/images/gallery/roma0422L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "roman watermelon bottle"
    },
    {
        src: '/images/gallery/roma0436L.jpg',
        srcSet: [
            '/images/gallery/roma0436s.jpg 107w',
            '/images/gallery/roma0436m.jpg 331w',
            '/images/gallery/roma0436L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "plebeian bowl"
    },
    {
        src: '/images/gallery/roma0459L.jpg',
        srcSet: [
            '/images/gallery/roma0459s.jpg 80w',
            '/images/gallery/roma0459m.jpg 248w',
            '/images/gallery/roma0459L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "large sculptural platter with knobby handles"
    },
    {
        src: '/images/gallery/roma0463L.jpg',
        srcSet: [
            '/images/gallery/roma0463s.jpg 80w',
            '/images/gallery/roma0463m.jpg 248w',
            '/images/gallery/roma0463L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "metallic box with spiked lid"
    },
    {
        src: '/images/gallery/roma0466L.jpg',
        srcSet: [
            '/images/gallery/roma0466s.jpg 107w',
            '/images/gallery/roma0466m.jpg 331w',
            '/images/gallery/roma0466L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "metallic box with spiked lid"
    },
    {
        src: '/images/gallery/roma0494L.jpg',
        srcSet: [
            '/images/gallery/roma0494s.jpg 80w',
            '/images/gallery/roma0494m.jpg 248w',
            '/images/gallery/roma0494L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 640vw"],
        width: 4,
        height: 3,
        title: "large sculptural platter with roman handles"
    },
    {
        src: '/images/gallery/roma0497L.jpg',
        srcSet: [
            '/images/gallery/roma0497s.jpg 80w',
            '/images/gallery/roma0497m.jpg 248w',
            '/images/gallery/roma0497L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "roman jar"
    },
    {
        src: '/images/gallery/roma0525L.jpg',
        srcSet: [
            '/images/gallery/roma0525s.jpg 107w',
            '/images/gallery/roma0525m.jpg 331w',
            '/images/gallery/roma0525L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "roman snake bowl"
    },
    {
        src: '/images/gallery/roma0528L.jpg',
        srcSet: [
            '/images/gallery/roma0528s.jpg 80w',
            '/images/gallery/roma0528m.jpg 248w',
            '/images/gallery/roma0528L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "pit-fired galley bottle"
    },
    {
        src: '/images/gallery/roma0418L.jpg',
        srcSet: [
            '/images/gallery/roma0418s.jpg 80w',
            '/images/gallery/roma0418m.jpg 248w',
            '/images/gallery/roma0418L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "roma bottles"
    },
    {
        src: '/images/gallery/roma0535L.jpg',
        srcSet: [
            '/images/gallery/roma0535s.jpg 107w',
            '/images/gallery/roma0535m.jpg 331w',
            '/images/gallery/roma0535L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "turquoise sushi plate"
    },
    {
        src: '/images/gallery/roma0540L.jpg',
        srcSet: [
            '/images/gallery/roma0540s.jpg 107w',
            '/images/gallery/roma0540m.jpg 331w',
            '/images/gallery/roma0540L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "roman bowl with spirals"
    },
    {
        src: '/images/gallery/roma0543L.jpg',
        srcSet: [
            '/images/gallery/roma0543s.jpg 107w',
            '/images/gallery/roma0543m.jpg 331w',
            '/images/gallery/roma0543L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "tibetan offering bowl"
    },
    {
        src: '/images/gallery/roma0548L.jpg',
        srcSet: [
            '/images/gallery/roma0548s.jpg 80w',
            '/images/gallery/roma0548m.jpg 248w',
            '/images/gallery/roma0548L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "roman seashell plate"
    },
    {
        src: '/images/gallery/roma0551L.jpg',
        srcSet: [
            '/images/gallery/roma0551s.jpg 80w',
            '/images/gallery/roma0551m.jpg 248w',
            '/images/gallery/roma0551L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "beige roman bowl - SOLD"
    },
    {
        src: '/images/gallery/roma0552L.jpg',
        srcSet: [
            '/images/gallery/roma0552s.jpg 80w',
            '/images/gallery/roma0552m.jpg 248w',
            '/images/gallery/roma0552L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: "small purple incense box"
    },
    {
        src: '/images/gallery/allegro0278L.jpg',
        srcSet: [
            '/images/gallery/allegro0278s.jpg 107w',
            '/images/gallery/allegro0278m.jpg 331w',
            '/images/gallery/allegro0278L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: "allegro, oceania, and fuji jars"
    },
    {
        src: '/images/gallery/mvc002S-L.jpg',
        srcSet: [
            '/images/gallery/mvc002S-s.jpg 45w',
            '/images/gallery/mvc002S-m.jpg 138w',
            '/images/gallery/mvc002S-L.jpg 250w'
        ],
        sizes: ["(min-width: 480px) 45vw, (min-width: 1024px) 138vw, 250vw"],
        width: 2,
        height: 4,
        title: "mystery vase - SOLD"
    }
];