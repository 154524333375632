import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import PageMain from './components/pageMain';
import PageGarden from './components/pageGarden';
import PageGallery from './components/pageGallery'
import PageJewelry from './components/pageJewelry';
import Footer from './components/footer'
import { Dropdown } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {

  /* const [activePage, setactivePage] = useState(<PageMain />); */

  return (
    <div className="App">
      <Navbar fixed="top">
        <Dropdown>
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" className="noCaret">
            ---
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="/"><img src="/images/logo-24x33.png" alt="." style={{marginRight:"20px"}} />Home</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="/garden">Adventures in Gardening</Dropdown.Item>
            <Dropdown.Item href="/gallery">Handmade Ceramics</Dropdown.Item>
            <Dropdown.Item href="/jewelry">Jewelry Exhibition</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="container justify-content-center">
          <Navbar.Brand href="/" className="text-nowrap">Loïe Valentine</Navbar.Brand>
        </div>
      </Navbar>
      {/* {activePage} */}
      <Router>
        <Switch>
          <Route path="/jewelry">
            <PageJewelry />
          </Route>
          <Route path="/gallery">
            <PageGallery />
          </Route>
          <Route path="/garden">
            <PageGarden />
          </Route>
          <Route path="/">
            <PageMain />
          </Route>
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
