import React from 'react';
import ColoredTile from './coloredTile'
import ImgTile from './imgTile'

export default class RightPanel extends React.Component {
    render() {
        const panelStyle = {
            display: "flex"
        }

        let className = "mainColumnRight";

        return (
            <div className={className}>
                <div style={ panelStyle }>
                    <ImgTile imgSrc="/images/allegro0265m.jpg"
                        imgSrcSet="/images/gallery/allegro0265s.jpg 80w,
                                   /images/allegro0265m.jpg 124w"
                        imgSizes="(max-width:450px) 80vw, (min-width:450px) 124vw, 124vw" />
                    <ColoredTile backColor="#336699" />
                    <ImgTile imgSrc="/images/allegro0359m.jpg"
                        imgSrcSet="/images/gallery/allegro0359s.jpg 80w,
                                   /images/allegro0359m.jpg 124w"
                        imgSizes="(max-width:450px) 80vw, (min-width:450px) 124vw, 124vw" />
                </div>
                <div style={ panelStyle }>
                    <ColoredTile backColor="#FF9900" />
                    <ImgTile imgSrc="/images/oceania0205m.jpg"
                        imgSrcSet="/images/gallery/oceania0205s.jpg 80w,
                                   /images/oceania0205m.jpg 124w"
                        imgSizes="(max-width:450px) 80vw, (min-width:450px) 124vw, 124vw" />
                    <ColoredTile backColor="#996633" />
                </div>
                <div style={ panelStyle }>
                    <ImgTile imgSrc="/images/roma0423m.jpg"
                        imgSrcSet="/images/gallery/roma0423s.jpg 80w,
                                   /images/roma0423m.jpg 124w"
                        imgSizes="(max-width:450px) 80vw, (min-width:450px) 124vw, 124vw" />
                    <ColoredTile backColor="#828200" />
                    <ImgTile imgSrc="/images/roma0541m.jpg"
                        imgSrcSet="/images/gallery/roma0541s.jpg 80w,
                                   /images/roma0541m.jpg 124w"
                        imgSizes="(max-width:450px) 80vw, (min-width:450px) 124vw, 124vw" />
                </div>
            </div>
        );
    }
}