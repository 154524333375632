import React, {useState, useCallback} from 'react';
import Gallery from 'react-photo-gallery';
import {photosJewelry} from './photosJewelry'
import Carousel, { Modal, ModalGateway } from "react-images";

function PageJewelry() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    
    const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
    
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };

    return (
        <div style={{marginTop:"65px", marginBottom:"65px"}} >
            <Gallery photos={photosJewelry} onClick={openLightbox} />
            <ModalGateway>
                {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                    <Carousel
                    currentIndex={currentImage}
                    views={photosJewelry.map(x => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title
                    }))}
                    />
                </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
}

export default PageJewelry;