import React from 'react';
import LeftPanel from './leftPanel';
import CenterPanel from './centerPanel';
import MobileCenterPanel from './mobileCenterPanel';
import RightPanel from './rightPanel';
import { Image } from 'react-bootstrap';

export default class PageMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
      
    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        // if screen width is too small, use mobile panels instead
        var leftPanel = <LeftPanel />;
        var centerPanel = <CenterPanel />;
        var rightPanel = <RightPanel />;
        if (this.state.width < 450) {
            leftPanel = null;
            centerPanel = <MobileCenterPanel />;
            rightPanel = <Image src="/images/butterfleur-large.png" fluid />;
        }

        return (
            <div className="mainTable">
                {leftPanel}
                {centerPanel}
                {rightPanel}
            </div>
        );
    }
}