import React from 'react';

export default class MobileCenterPanel extends React.Component {
    render() {
        return (
            <div className="mainColumnCenter">
                <div><img src="/images/logo-large.png" alt="Lo" className="mainImageCenter" /></div>
                <div className="mobileCenterLabelsDiv">
                    <div className="mainSubLabelCenter"><a href="/garden">Native Gardening</a></div>
                    <div className="mainSubLabelCenter"><a href="/gallery">Handmade Ceramics</a></div>
                </div>
            </div>
        );
    }
}