export const gardenItems = [
    {
        heading: "Mystery Mushrooms",
        latinName: "",
        type: "",
        body: "These tiny mushrooms appeared near a rock outcrop in our backyard, " +
              "shortly after a day of rain.  A day later, they were all gone.  We " +
              "did our best to identify them, but mushrooms are notoriously difficult " +
              "to identify.",
        wikiLink: "",
        imgs: [
            {
                date: "05/25/2020",
                src:'/images/garden/2020-MushroomsL.jpeg',
                srcSet: [
                    '/images/garden/2020-MushroomsS.jpeg 107w',
                    '/images/garden/2020-MushroomsM.jpeg 331w',
                    '/images/garden/2020-MushroomsL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            },
            {
                date: "05/25/2020",
                src:'/images/garden/2020-Mushrooms2L.jpeg',
                srcSet: [
                    '/images/garden/2020-Mushrooms2S.jpeg 107w',
                    '/images/garden/2020-Mushrooms2M.jpeg 331w',
                    '/images/garden/2020-Mushrooms2L.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Black and Blue Sage",
        latinName: "Salvia Guaranitica",
        type: "Perennial",
        body: "This striking member of the salvia family grows in our full-sun garden.  " +
              "It's also known as anise-scented sage.  Several cultivars have blue flowers, " +
              "but ours is more violet.",
        wikiLink: "https://en.wikipedia.org/wiki/Salvia_guaranitica",
        imgs: [
            {
                date: "05/30/2020",
                src:'/images/garden/2020-BlueSageL.jpeg',
                srcSet: [
                    '/images/garden/2020-BlueSageS.jpeg 107w',
                    '/images/garden/2020-BlueSageM.jpeg 331w',
                    '/images/garden/2020-BlueSageL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            },
            {
                date: "05/30/2020",
                src:'/images/garden/2020-BlueSage2L.jpeg',
                srcSet: [
                    '/images/garden/2020-BlueSage2S.jpeg 107w',
                    '/images/garden/2020-BlueSage2M.jpeg 331w',
                    '/images/garden/2020-BlueSage2L.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Matrona",
        latinName: "Hylotelephium Telephium",
        type: "Perennial",
        body: "Hylotelephium Telephium has many common names, but this particular " +
              "cultivar is known as matrona.  It grows in our full-sun garden and " +
              "attracts butterflies and other pollinators.",
        wikiLink: "https://en.wikipedia.org/wiki/Hylotelephium_telephium",
        imgs: [
            {
                date: "05/30/2020",
                src:'/images/garden/2020-MatronaL.jpeg',
                srcSet: [
                    '/images/garden/2020-MatronaS.jpeg 107w',
                    '/images/garden/2020-MatronaM.jpeg 331w',
                    '/images/garden/2020-MatronaL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            },
            {
                date: "05/30/2020",
                src:'/images/garden/2020-MatronaCloseL.jpeg',
                srcSet: [
                    '/images/garden/2020-MatronaCloseS.jpeg 107w',
                    '/images/garden/2020-MatronaCloseM.jpeg 331w',
                    '/images/garden/2020-MatronaCloseL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Brown-Eyed Susan",
        latinName: "Rudbeckia Triloba",
        type: "Perennial",
        native: true,
        body: "We planted these as ground cover in our primary garden.  " +
              "Apart from their pretty appearance, they also attract goldfinches, " +
              "especially after the flowers have gone to seed.",
        wikiLink: "https://en.wikipedia.org/wiki/Rudbeckia_triloba",
        imgs: [
            {
                date: "05/30/2020",
                src:'/images/garden/2020-BrownSusanL.jpeg',
                srcSet: [
                    '/images/garden/2020-BrownSusanS.jpeg 107w',
                    '/images/garden/2020-BrownSusanM.jpeg 331w',
                    '/images/garden/2020-BrownSusanL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Buckwheat",
        latinName: "Fagopyrum Esculentum",
        type: "Annual",
        edible: true,
        body: "It's difficult to see its tiny flowers in this picture, " +
              "but this buckwheat was planted as a cover crop to help enrich " +
              "the soil in our primary garden.",
        wikiLink: "https://en.wikipedia.org/wiki/Buckwheat",
        imgs: [
            {
                date: "05/30/2020",
                src:'/images/garden/2020-BuckwheatL.jpeg',
                srcSet: [
                    '/images/garden/2020-BuckwheatS.jpeg 107w',
                    '/images/garden/2020-BuckwheatM.jpeg 331w',
                    '/images/garden/2020-BuckwheatL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Daylily",
        latinName: "Hemerocallis",
        type: "Perennial",
        body: "There are so many different cultivars of daylilies that we're " +
              "not really sure which one this is.  We took this photo a little late " +
              "as the blooms are starting to die off.",
        wikiLink: "https://en.wikipedia.org/wiki/Daylily",
        imgs: [
            {
                date: "05/30/2020",
                src:'/images/garden/2020-DaylilyL.jpeg',
                srcSet: [
                    '/images/garden/2020-DaylilyS.jpeg 107w',
                    '/images/garden/2020-DaylilyM.jpeg 331w',
                    '/images/garden/2020-DaylilyL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Butterfly Weed",
        latinName: "Asclepias Tuberosa",
        type: "Perennial",
        native: true,
        body: "This butterfly weed is in the milkweed family.  We're using it as " +
              "another ground cover in our primary garden, but it's vibrant little " +
              "blooms also make it attractive to various pollinators.",
        wikiLink: "https://en.wikipedia.org/wiki/Asclepias_tuberosa",
        imgs: [
            {
                date: "05/30/2020",
                src:'/images/garden/2020-ButterflyWeedL.jpeg',
                srcSet: [
                    '/images/garden/2020-ButterflyWeedS.jpeg 107w',
                    '/images/garden/2020-ButterflyWeedM.jpeg 331w',
                    '/images/garden/2020-ButterflyWeedL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Butterfly Bush",
        latinName: "Buddleja Davidii",
        type: "Perennial",
        body: "Butterflies, bees, and hummingbirds love this one. It sits just south " +
              "of our primary garden and gets a little more shade in the late afternoon.",
        wikiLink: "https://en.wikipedia.org/wiki/Buddleja_davidii",
        imgs: [
            {
                date: "05/30/2020",
                src:'/images/garden/2020-ButterflyBushL.jpeg',
                srcSet: [
                    '/images/garden/2020-ButterflyBushS.jpeg 107w',
                    '/images/garden/2020-ButterflyBushM.jpeg 331w',
                    '/images/garden/2020-ButterflyBushL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Hydrangea",
        latinName: "Hydrangea",
        type: "Perennial",
        body: "This hydrangea came from a cutting of a plant in grandmother's " +
              "yard.  We're not sure which cultivar this might be, but it seems to " +
              "be flourishing in a shady corner of the yard.",
        wikiLink: "https://en.wikipedia.org/wiki/Hydrangea",
        imgs: [
            {
                date: "05/30/2020",
                src:'/images/garden/2020-HydrangeaL.jpeg',
                srcSet: [
                    '/images/garden/2020-HydrangeaS.jpeg 107w',
                    '/images/garden/2020-HydrangeaM.jpeg 331w',
                    '/images/garden/2020-HydrangeaL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            },
            {
                date: "05/30/2020",
                src:'/images/garden/2020-HydrangeaCloseL.jpeg',
                srcSet: [
                    '/images/garden/2020-HydrangeaCloseS.jpeg 107w',
                    '/images/garden/2020-HydrangeaCloseM.jpeg 331w',
                    '/images/garden/2020-HydrangeaCloseL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            },
            {
                date: "05/30/2020",
                src:'/images/garden/2020-HydrangeaClose2L.jpeg',
                srcSet: [
                    '/images/garden/2020-HydrangeaClose2S.jpeg 107w',
                    '/images/garden/2020-HydrangeaClose2M.jpeg 331w',
                    '/images/garden/2020-HydrangeaClose2L.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            },
            {
                date: "05/30/2020",
                src:'/images/garden/2020-HydrangeaBudL.jpeg',
                srcSet: [
                    '/images/garden/2020-HydrangeaBudS.jpeg 107w',
                    '/images/garden/2020-HydrangeaBudM.jpeg 331w',
                    '/images/garden/2020-HydrangeaBudL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Yucca",
        latinName: "Yucca Filamentosa",
        type: "Perennial",
        native: true,
        edible: true,
        body: "We planted this yucca to serve as a spikey barrier at the edge of our property. ",
        wikiLink: "https://en.wikipedia.org/wiki/Yucca_filamentosa",
        imgs: [
            {
                date: "05/30/2020",
                src:'/images/garden/2020-YuccaL.jpeg',
                srcSet: [
                    '/images/garden/2020-YuccaS.jpeg 107w',
                    '/images/garden/2020-YuccaM.jpeg 331w',
                    '/images/garden/2020-YuccaL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            },
            {
                date: "05/30/2020",
                src:'/images/garden/2020-YuccaFlowerL.jpeg',
                srcSet: [
                    '/images/garden/2020-YuccaFlowerS.jpeg 107w',
                    '/images/garden/2020-YuccaFlowerM.jpeg 331w',
                    '/images/garden/2020-YuccaFlowerL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Husker Red Penstemon",
        latinName: "Penstemon Digitalis",
        type: "Perennial",
        native: true,
        body: "Hummingbirds really seem to like the flowers on this plant. " +
              "We like its dramatic appearance, even without the flowers. This cultivar " +
              "is known as Husker Red.",
        wikiLink: "https://en.wikipedia.org/wiki/Penstemon_digitalis",
        imgs: [
            {
                date: "05/16/2020",
                src:'/images/garden/2020-huskersRedL.jpeg',
                srcSet: [
                    '/images/garden/2020-huskersRedS.jpeg 107w',
                    '/images/garden/2020-huskersRedM.jpeg 331w',
                    '/images/garden/2020-huskersRedL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Goldmound Spiraea",
        latinName: "Spiraea Japonica",
        type: "Perennial",
        body: "This cultivar of spiraea is known as Goldmound. The pink flowers " +
              "are remniscent of raspberries, and the green foliage turns a lush " +
              "golden color in the fall.",
        wikiLink: "https://en.wikipedia.org/wiki/Spiraea_japonica",
        imgs: [
            {
                date: "05/16/2020",
                src:'/images/garden/2020-goldmoundL.jpeg',
                srcSet: [
                    '/images/garden/2020-goldmoundS.jpeg 107w',
                    '/images/garden/2020-goldmoundM.jpeg 331w',
                    '/images/garden/2020-goldmoundL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Sedum",
        latinName: "Sedum Acre",
        type: "Perennial",
        body: "This ground cover serves as a nice filler between the cracks " +
              "in our garden paths.  It's so prolific that we often have to pull " +
              "it back, especially during spring, when it's in full bloom.",
        wikiLink: "https://en.wikipedia.org/wiki/Sedum_acre",
        imgs: [
            {
                date: "05/16/2020",
                src:'/images/garden/2020-sedumL.jpeg',
                srcSet: [
                    '/images/garden/2020-sedumS.jpeg 107w',
                    '/images/garden/2020-sedumM.jpeg 331w',
                    '/images/garden/2020-sedumL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Oakleaf Hydrangea",
        latinName: "Hydrangea Quercifolia",
        type: "Perennial",
        native: true,
        body: "When we first got this plant, it was tiny.  Now, it's " +
              "grown into an enormous shrub, flourishing in the shade of " +
              "our backyard.",
        wikiLink: "https://en.wikipedia.org/wiki/Hydrangea_quercifolia",
        imgs: [
            {
                date: "05/16/2020",
                src:'/images/garden/2020-oakleafHydraL.jpeg',
                srcSet: [
                    '/images/garden/2020-oakleafHydraS.jpeg 107w',
                    '/images/garden/2020-oakleafHydraM.jpeg 331w',
                    '/images/garden/2020-oakleafHydraL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Sweet Shrub",
        latinName: "Calycanthus Floridus",
        type: "Perennial",
        native: true,
        body: "These grow wild, all along the wooded paths near our home. " +
              "They're appropriately named, as they have a sweet, but never " +
              "overwhelming, candy-like smell. " +
              "This one is our our back yard and it's thriving in the shade.",
        wikiLink: "https://en.wikipedia.org/wiki/Calycanthus_floridus",
        imgs: [
            {
                date: "05/03/2020",
                src:'/images/garden/2020-sweetshrubL.jpeg',
                srcSet: [
                    '/images/garden/2020-sweetshrubS.jpeg 107w',
                    '/images/garden/2020-sweetshrubM.jpeg 331w',
                    '/images/garden/2020-sweetshrubL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Purple Pitcher Plant",
        latinName: "Sarracenia Purpurea",
        type: "Perennial",
        native: true,
        body: "We have a tiny bog garden in a planter near our house, and " +
              "this is one of the flowers from the pitchter plant.  It's native " +
              "to swampy areas, but it ranges up and down  " +
              "the east coast and all across Canada.",
        wikiLink: "https://en.wikipedia.org/wiki/Sarracenia_purpurea",
        imgs: [
            {
                date: "05/03/2020",
                src:'/images/garden/2020-purplePitcherL.jpeg',
                srcSet: [
                    '/images/garden/2020-purplePitcherS.jpeg 107w',
                    '/images/garden/2020-purplePitcherM.jpeg 331w',
                    '/images/garden/2020-purplePitcherL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Wood Sorrel",
        latinName: "Oxalis",
        type: "Annual",
        edible: true,
        body: "It's tough to know which species of oxalis this is, but it pops up around " +
              "the yard.  This one has darker green leaves with " +
              "a pretty crimson tint.",
        wikiLink: "https://en.wikipedia.org/wiki/Oxalis",
        imgs: [
            {
                date: "05/03/2020",
                src:'/images/garden/2020-oxalisL.jpeg',
                srcSet: [
                    '/images/garden/2020-oxalisS.jpeg 107w',
                    '/images/garden/2020-oxalisM.jpeg 331w',
                    '/images/garden/2020-oxalisL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Foxglove",
        latinName: "Digitalis Purpurea",
        type: "Biennial",
        toxic: true,
        body: "Identifying the species of this foxglove is difficult, but " +
              "we think it's purpurea.  We scattered seeds in our backyard, " +
              "but the white ones are the only ones that keep coming up.",
        wikiLink: "https://en.wikipedia.org/wiki/Digitalis_purpurea",
        imgs: [
            {
                date: "05/03/2020",
                src:'/images/garden/2020-digitalisL.jpeg',
                srcSet: [
                    '/images/garden/2020-digitalisS.jpeg 107w',
                    '/images/garden/2020-digitalisM.jpeg 331w',
                    '/images/garden/2020-digitalisL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Hubricht's Bluestar",
        latinName: "Amsonia Hubrichtii",
        type: "Perennial",
        body: "This amsonia grows much bigger than the native variety, and its flowers " +
              "are significantly different.",
        wikiLink: "https://en.wikipedia.org/wiki/Amsonia_hubrichtii",
        imgs: [
            {
                date: "05/02/2020",
                src:'/images/garden/2020-hubrichtL.jpeg',
                srcSet: [
                    '/images/garden/2020-hubrichtS.jpeg 107w',
                    '/images/garden/2020-hubrichtM.jpeg 331w',
                    '/images/garden/2020-hubrichtL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            },
            {
                date: "05/02/2020",
                src:'/images/garden/2020-hubricht2L.jpeg',
                srcSet: [
                    '/images/garden/2020-hubricht2S.jpeg 107w',
                    '/images/garden/2020-hubricht2M.jpeg 331w',
                    '/images/garden/2020-hubricht2L.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Eastern Bluestar",
        latinName: "Amsonia Tabernaemontana",
        type: "Perennial",
        native: true,
        body: "These tiny flowers are easy to miss, but as seen from this close-up, " +
              "they make quite an impact.  The group photo gives a better perspective " +
              "of how easily they blend in with surrounding plants.",
        wikiLink: "https://en.wikipedia.org/wiki/Amsonia_tabernaemontana",
        imgs: [
            {
                date: "05/02/2020",
                src:'/images/garden/2020-easternBluestarL.jpeg',
                srcSet: [
                    '/images/garden/2020-easternBluestarS.jpeg 107w',
                    '/images/garden/2020-easternBluestarM.jpeg 331w',
                    '/images/garden/2020-easternBluestarL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            },
            {
                date: "05/02/2020",
                src:'/images/garden/2020-easternBluestar2L.jpeg',
                srcSet: [
                    '/images/garden/2020-easternBluestar2S.jpeg 107w',
                    '/images/garden/2020-easternBluestar2M.jpeg 331w',
                    '/images/garden/2020-easternBluestar2L.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Cilantro",
        latinName: "Coriandrum Sativum",
        type: "Annual",
        edible: true,
        body: "We planted this coriander to have fresh cilantro for our dinners, " +
              "but this one decided it would rather focus more on its blooms than " +
              "providing flavor for our meals.",
        wikiLink: "https://en.wikipedia.org/wiki/Coriander",
        imgs: [
            {
                date: "05/02/2020",
                src:'/images/garden/2020-cilantroL.jpeg',
                srcSet: [
                    '/images/garden/2020-cilantroS.jpeg 107w',
                    '/images/garden/2020-cilantroM.jpeg 331w',
                    '/images/garden/2020-cilantroL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Siberian Iris",
        latinName: "Iris Sibirica",
        type: "Perennial",
        body: "Despite seeing these in many stores, this cultivar came from group of irises " +
              "that were growing in grandma's yard for years.",
        wikiLink: "https://en.wikipedia.org/wiki/Iris_sibirica",
        imgs: [
            {
                date: "05/02/2020",
                src:'/images/garden/2020-siberianIrisL.jpeg',
                srcSet: [
                    '/images/garden/2020-siberianIrisS.jpeg 107w',
                    '/images/garden/2020-siberianIrisM.jpeg 331w',
                    '/images/garden/2020-siberianIrisL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Eastern Red Columbine",
        latinName: "Aquilegia Canadensis",
        type: "Perennial",
        native: true,
        body: "These native columbines can be difficult to find. They're not as " +
              "showy as some of the other varieties, but they're very pretty, nonetheless. " +
              "As a bonus, the hummingbirds seem to love them.",
        wikiLink: "https://en.wikipedia.org/wiki/Aquilegia_canadensis",
        imgs: [
            {
                date: "05/02/2020",
                src:'/images/garden/2020-redColumbineL.jpeg',
                srcSet: [
                    '/images/garden/2020-redColumbineS.jpeg 107w',
                    '/images/garden/2020-redColumbineM.jpeg 331w',
                    '/images/garden/2020-redColumbineL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Purple White Columbine",
        latinName: "Aquilegia Vulgaris",
        type: "Perennial",
        body: "There are so many varieties of columbines that they're difficult to " +
              "identify.  We got this one from a family friend in South Carolina. It " +
              "seems to love the shady area under our maple tree.",
        wikiLink: "https://en.wikipedia.org/wiki/Aquilegia_vulgaris",
        imgs: [
            {
                date: "05/02/2020",
                src:'/images/garden/2020-purpleColumbineL.jpeg',
                srcSet: [
                    '/images/garden/2020-purpleColumbineS.jpeg 107w',
                    '/images/garden/2020-purpleColumbineM.jpeg 331w',
                    '/images/garden/2020-purpleColumbineL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Yellow Indigo",
        latinName: "Baptisia Sphaerocarpa",
        type: "Perennial",
        body: "We got this baptisia from the Atlanta Botanical Garden.  They couldn't " +
              "get it to grow and were going to throw it out.  We planted it in a part " +
              "of our yard with the most sun exposure we have, and it's thriving.",
        wikiLink: "https://en.wikipedia.org/wiki/Baptisia_sphaerocarpa",
        imgs: [
            {
                date: "05/02/2020",
                src:'/images/garden/2020-yellowindigoL.jpeg',
                srcSet: [
                    '/images/garden/2020-yellowindigoS.jpeg 107w',
                    '/images/garden/2020-yellowindigoM.jpeg 331w',
                    '/images/garden/2020-yellowindigoL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Phlox",
        latinName: "Phlox Stolonifera",
        type: "Perennial",
        native: true,
        body: "We have a few different varieties of phlox growing around the " +
              "house.  This one is growing in a relatively shady area near our " +
              "quince bush.",
        wikiLink: "https://en.wikipedia.org/wiki/Phlox_stolonifera",
        imgs: [
            {
                date: "03/28/2020",
                src:'/images/garden/2020-PhloxL.jpeg',
                srcSet: [
                    '/images/garden/2020-PhloxS.jpeg 107w',
                    '/images/garden/2020-PhloxM.jpeg 331w',
                    '/images/garden/2020-PhloxL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Epimedium",
        latinName: "Epimedium",
        type: "Perennial",
        body: "As pretty as this flower is, it's odd to think that this plant " +
              "is also known as horny goat weed.  It's growing on our back " +
              "patio, in the same little garden as our snowdrops.",
        wikiLink: "https://en.wikipedia.org/wiki/Epimedium",
        imgs: [
            {
                date: "03/28/2020",
                src:'/images/garden/2020-EpimediumL.jpeg',
                srcSet: [
                    '/images/garden/2020-EpimediumS.jpeg 107w',
                    '/images/garden/2020-EpimediumM.jpeg 331w',
                    '/images/garden/2020-EpimediumL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Common Blue Violets",
        latinName: "Viola Sororia",
        type: "Perennial",
        edible: true,
        body: "These wild violets have been popping up along the cracks in our " +
              "driveway.  Some consider these a weed, but we thought " +
              "they were too pretty to kill.  We have a few white ones as well, " +
              "which we believe are in the priceana variety.",
        wikiLink: "https://en.wikipedia.org/wiki/Viola_sororia",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-VioletsL.jpeg",
                srcSet: [
                    '/images/garden/2020-VioletsS.jpeg 107w',
                    '/images/garden/2020-VioletsM.jpeg 331w',
                    '/images/garden/2020-VioletsL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            },
            {
                caption: "one of the few white violets",
                date: "03/22/2020",
                src:'/images/garden/2020-WhiteVioletL.jpeg',
                srcSet: [
                    '/images/garden/2020-WhiteVioletS.jpeg 107w',
                    '/images/garden/2020-WhiteVioletM.jpeg 331w',
                    '/images/garden/2020-WhiteVioletL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Broccoli Rabe",
        latinName: "Brassica Rapa",
        type: "Annual?",
        edible: true,
        body: "We planted this broccoli rabe as experiment to see what it might do in our yard. " +
              "It made some pretty blooms, but it never produced the broccoli stalks that we " +
              "wanted for culinary use, so we chopped it up after the blooms died and used it to " +
              "enrich the soil for some other plants in the yard.",
        wikiLink: "https://en.wikipedia.org/wiki/Rapini",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-BroccoliRabeL.jpeg",
                srcSet: [
                    '/images/garden/2020-BroccoliRabeS.jpeg 107w',
                    '/images/garden/2020-BroccoliRabeM.jpeg 331w',
                    '/images/garden/2020-BroccoliRabeL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
            }
        ]
    },
    {
        heading: "Bugleweed",
        latinName: "Ajuga Reptans",
        type: "Perennial",
        body: "This buggleweed is growing in our primary garden path.  Some consider " +
              "it a weed, but it's a pretty filler between the stepping stones.",
        wikiLink: "https://en.wikipedia.org/wiki/Ajuga_reptans",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-BugleweedL.jpeg",
                srcSet: [
                    '/images/garden/2020-BugleweedS.jpeg 107w',
                    '/images/garden/2020-BugleweedM.jpeg 331w',
                    '/images/garden/2020-BugleweedL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
            }
        ]
    },
    {
        heading: "Daffodils",
        latinName: "Narcissus Pseudonarcissus",
        type: "Perennial",
        body: "These daffodils are always one of the first signs of Spring.  They have been popping up " +
              "around our dogwood tree.",
        wikiLink: "https://en.wikipedia.org/wiki/Narcissus_pseudonarcissus",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-DaffodilsL.jpeg",
                srcSet: [
                    '/images/garden/2020-DaffodilsS.jpeg 107w',
                    '/images/garden/2020-DaffodilsM.jpeg 331w',
                    '/images/garden/2020-DaffodilsL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Autumn Fern",
        latinName: "Dryopteris Erythrosora",
        type: "Perennial",
        body: "These ferns are a stable in our shady back yard.  They make excellent " +
              "ground cover on our hill.  The fiddlehead photo is from back in March.  Two " +
              "months later, they've grown a lot and lost most of the reddish color that " +
              "gives them their name.",
        wikiLink: "https://en.wikipedia.org/wiki/Dryopteris_erythrosora",
        imgs: [
            {
                date: "05/16/2020",
                src: "/images/garden/2020-autumnFernsL.jpeg",
                srcSet: [
                    '/images/garden/2020-autumnFernsS.jpeg 107w',
                    '/images/garden/2020-autumnFernsM.jpeg 331w',
                    '/images/garden/2020-autumnFernsL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            },
            {
                caption: "Fiddleheads from early March",
                date: "03/22/2020",
                src: "/images/garden/2020-FernFiddleheadsL.jpeg",
                srcSet: [
                    '/images/garden/2020-FernFiddleheadsS.jpeg 107w',
                    '/images/garden/2020-FernFiddleheadsM.jpeg 331w',
                    '/images/garden/2020-FernFiddleheadsL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Yellow Fumitory",
        latinName: "Corydalis Lutea",
        type: "Perennial",
        body: "These are growing around our compost bins.",
        wikiLink: "https://en.wikipedia.org/wiki/Pseudofumaria_lutea",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-YellowFumitoryL.jpeg",
                srcSet: [
                    '/images/garden/2020-YellowFumitoryS.jpeg 107w',
                    '/images/garden/2020-YellowFumitoryM.jpeg 331w',
                    '/images/garden/2020-YellowFumitoryL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Japanese Roof Irises",
        latinName: "Iris tectorum",
        type: "Perennial",
        body: "Our hill is flooded with these.  They always make an impact when they're all in bloom.",
        wikiLink: "https://en.wikipedia.org/wiki/Iris_tectorum",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-IrisesL.jpeg",
                srcSet: [
                    '/images/garden/2020-IrisesS.jpeg 107w',
                    '/images/garden/2020-IrisesM.jpeg 331w',
                    '/images/garden/2020-IrisesL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "White Quince",
        latinName: "Cydonia Oblonga",
        type: "Perennial",
        body: "This white quince has lived for years at the bottom of our front steps.  The photo " +
              "here is just past peak bloom.",
        wikiLink: "https://en.wikipedia.org/wiki/Narcissus_pseudonarcissus",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-QuinceL.jpeg",
                srcSet: [
                    '/images/garden/2020-QuinceS.jpeg 80w',
                    '/images/garden/2020-QuinceM.jpeg 248w',
                    '/images/garden/2020-QuinceL.jpeg 480w'
                ],
                sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"]
            }
        ]
    },
    {
        heading: "Mini Daffodil",
        latinName: "Narcissus Pseudonarcissus",
        type: "Perennial",
        body: "If you like daffodils but think they're too big, this one is for you.  It's " +
              "much smaller than the traditional ones.",
        wikiLink: "https://en.wikipedia.org/wiki/Narcissus_pseudonarcissus",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-MiniDaffodilL.jpeg",
                srcSet: [
                    '/images/garden/2020-MiniDaffodilS.jpeg 80w',
                    '/images/garden/2020-MiniDaffodilM.jpeg 248w',
                    '/images/garden/2020-MiniDaffodilL.jpeg 480w'
                ],
                sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"]
            }
        ]
    },
    {
        heading: "Snowdrops",
        latinName: "Galanthus Nivalis",
        type: "Perennial",
        body: "These awesome little guys are growing around our back patio.",
        wikiLink: "https://en.wikipedia.org/wiki/Galanthus_nivalis",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-SnowdropsL.jpeg",
                srcSet: [
                    '/images/garden/2020-SnowdropsS.jpeg 80w',
                    '/images/garden/2020-SnowdropsM.jpeg 248w',
                    '/images/garden/2020-SnowdropsL.jpeg 480w'
                ],
                sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"]
            }
        ]
    },
    {
        heading: "Spanish Hyacinth",
        latinName: "Hyacinthus Orientalis",
        type: "Perennial",
        body: "The hyacinth with white flowers is blooming around our compost bin " +
              "in our backyard.  The one with purple flowers is in our front yard " +
              "where it gets more afternoon sun.",
        wikiLink: "https://en.wikipedia.org/wiki/Hyacinth_(plant)",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-SpanishHyacinthL.jpeg",
                srcSet: [
                    '/images/garden/2020-SpanishHyacinthS.jpeg 80w',
                    '/images/garden/2020-SpanishHyacinthM.jpeg 248w',
                    '/images/garden/2020-SpanishHyacinthL.jpeg 480w'
                ],
                sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"]
            },
            {
                date: "03/28/2020",
                src: "/images/garden/2020-PurpleSpanishHyacinthL.jpeg",
                srcSet: [
                    '/images/garden/2020-PurpleSpanishHyacinthS.jpeg 80w',
                    '/images/garden/2020-PurpleSpanishHyacinthM.jpeg 248w',
                    '/images/garden/2020-PurpleSpanishHyacinthL.jpeg 480w'
                ],
                sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"]
            }
        ]
    },
    {
        heading: "Viginia Bluebells",
        latinName: "Mertensia Virginica",
        type: "Perennial",
        native: true,
        body: "These are another pretty flower growing in our backyard.",
        wikiLink: "https://en.wikipedia.org/wiki/Mertensia_virginica",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-VirginiaBluebellsL.jpeg",
                srcSet: [
                    '/images/garden/2020-VirginiaBluebellsS.jpeg 80w',
                    '/images/garden/2020-VirginiaBluebellsM.jpeg 248w',
                    '/images/garden/2020-VirginiaBluebellsL.jpeg 480w'
                ],
                sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"]
            }
        ]
    },
    {
        heading: "Woodland Poppy",
        latinName: "Stylophorum Diphyllum",
        type: "Perennial",
        native: true,
        body: "This wild little guy always starts off small and then quickly grows. " +
              "The chipmunks like to eat its buds.",
        wikiLink: "https://en.wikipedia.org/wiki/Stylophorum_diphyllum",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-WoodlandPoppyL.jpeg",
                srcSet: [
                    '/images/garden/2020-WoodlandPoppyS.jpeg 107w',
                    '/images/garden/2020-WoodlandPoppyM.jpeg 331w',
                    '/images/garden/2020-WoodlandPoppyL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Hellebores",
        latinName: "Helleborus",
        type: "Perennial",
        body: "These have been growing our backyard for years and have really started " +
              "expanding their presence our our hill.",
        wikiLink: "https://en.wikipedia.org/wiki/Hellebore",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-HelleboresL.jpeg",
                srcSet: [
                    '/images/garden/2020-HelleboresS.jpeg 107w',
                    '/images/garden/2020-HelleboresM.jpeg 331w',
                    '/images/garden/2020-HelleboresL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Japanese Painted Fern",
        latinName: "Athyrium Niponicum",
        type: "Perennial",
        body: "This frosted-looking fern is growing in the same space on our patio with " +
              "the snowdrops.",
        wikiLink: "https://en.wikipedia.org/wiki/Athyrium_niponicum",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-FernL.jpeg",
                srcSet: [
                    '/images/garden/2020-FernS.jpeg 107w',
                    '/images/garden/2020-FernM.jpeg 331w',
                    '/images/garden/2020-FernL.jpeg 640w'
                ],
                sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"]
            }
        ]
    },
    {
        heading: "Forget-Me-Not",
        latinName: "Myosotis",
        type: "Perennial",
        body: "We have a small pot full of bog plants, and these pretty blue flowers " +
              "are in among the pitcher plants and others.",
        wikiLink: "https://en.wikipedia.org/wiki/Myosotis",
        imgs: [
            {
                date: "03/22/2020",
                src: "/images/garden/2020-Forget-Me-NotL.jpeg",
                srcSet: [
                    '/images/garden/2020-Forget-Me-NotS.jpeg 80w',
                    '/images/garden/2020-Forget-Me-NotM.jpeg 248w',
                    '/images/garden/2020-Forget-Me-NotL.jpeg 480w'
                ],
                sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"]
            }
        ]
    }  
]