export const photosJewelry = [
    {
        src: '/images/jewelry/IMG_0744L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0744S.jpg 80w',
            '/images/jewelry/IMG_0744M.jpg 248w',
            '/images/jewelry/IMG_0744L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0745L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0745S.jpg 80w',
            '/images/jewelry/IMG_0745M.jpg 248w',
            '/images/jewelry/IMG_0745L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0746L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0746S.jpg 80w',
            '/images/jewelry/IMG_0746M.jpg 248w',
            '/images/jewelry/IMG_0746L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0747L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0747S.jpg 80w',
            '/images/jewelry/IMG_0747M.jpg 248w',
            '/images/jewelry/IMG_0747L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0749L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0749S.jpg 107w',
            '/images/jewelry/IMG_0749M.jpg 331w',
            '/images/jewelry/IMG_0749L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0751L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0751S.jpg 107w',
            '/images/jewelry/IMG_0751M.jpg 331w',
            '/images/jewelry/IMG_0751L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0752L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0752S.jpg 80w',
            '/images/jewelry/IMG_0752M.jpg 248w',
            '/images/jewelry/IMG_0752L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0753L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0753S.jpg 80w',
            '/images/jewelry/IMG_0753M.jpg 248w',
            '/images/jewelry/IMG_0753L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0754L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0754S.jpg 80w',
            '/images/jewelry/IMG_0754M.jpg 248w',
            '/images/jewelry/IMG_0754L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0755L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0755S.jpg 107w',
            '/images/jewelry/IMG_0755M.jpg 331w',
            '/images/jewelry/IMG_0755L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0756L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0756S.jpg 80w',
            '/images/jewelry/IMG_0756M.jpg 248w',
            '/images/jewelry/IMG_0756L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0757L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0757S.jpg 80w',
            '/images/jewelry/IMG_0757M.jpg 248w',
            '/images/jewelry/IMG_0757L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0758L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0758S.jpg 80w',
            '/images/jewelry/IMG_0758M.jpg 248w',
            '/images/jewelry/IMG_0758L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0760L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0760S.jpg 107w',
            '/images/jewelry/IMG_0760M.jpg 331w',
            '/images/jewelry/IMG_0760L.jpg 640w'
        ],
        sizes: ["(min-width: 480px) 107vw, (min-width: 1024px) 331vw, 640vw"],
        width: 4,
        height: 3,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0761L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0761S.jpg 80w',
            '/images/jewelry/IMG_0761M.jpg 248w',
            '/images/jewelry/IMG_0761L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: ""
    },
    {
        src: '/images/jewelry/IMG_0762L.jpg',
        srcSet: [
            '/images/jewelry/IMG_0762S.jpg 80w',
            '/images/jewelry/IMG_0762M.jpg 248w',
            '/images/jewelry/IMG_0762L.jpg 480w'
        ],
        sizes: ["(min-width: 480px) 80vw, (min-width: 1024px) 248vw, 480vw"],
        width: 3,
        height: 4,
        title: ""
    }
];