import React from 'react';

export default class LeftPanel extends React.Component {
    render() {
        return (
            <div className="mainColumnLeft">
                {/* <img src="/images/mobiletop.png"
                    srcSet="/images/mobiletop.png 378w,
                            /images/butterfleur-large.png 401w"
                    sizes="(min-width:800px) 401w,
                           (min-width:300px) 378w"
                    alt="..." className="mainImageLeft" /> */}
                <picture>
                    <source srcSet="/images/left-mainM.jpeg" media="(min-width: 300px)"/>
                    <source srcSet="/images/left-mainL.jpeg" media="(min-width: 800px)"/>
                    <img src="/images/left-mainL.jpeg" alt="..." className="mainImageLeft" />
                </picture>
            </div>
        );
    }
}