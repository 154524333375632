import React from 'react';

export default class ImgTile extends React.Component {
    render() {
        let className = "mainImageRight";
        const tileStyle = {
            margin: "2px 2px 0px 2px"
        }

        return (
            <div style={ tileStyle }>
                <a href="/gallery">
                    <img className={className} src={this.props.imgSrc} srcSet={this.props.imgSrcSet}
                        sizes={this.props.imgSizes} alt="..." />
                </a>
            </div>
        );
    }
}