import React from 'react';

const currentYear = new Date().getFullYear();

export default class Footer extends React.Component {
    render() {
        var style = {
            backgroundColor: "#828200", //"#F8F8F8",
            borderTop: "1px solid #E7E7E7",
            textAlign: "center",
            padding: "30px",
            position: "fixed",
            left: "0",
            bottom: "0",
            height: "20px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }

        var textStyle = {
            fontSize: "14px"
        }

        return (
            <div style={style}>
                <div style={textStyle}>&copy; {currentYear} - Loïe Valentine</div>
            </div>
        );
    }
}