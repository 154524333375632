import React from 'react';

export default class CenterPanel extends React.Component {
    render() {
        return (
            <div className="mainColumnCenter">
                <div><img src="/images/logo-large.png" alt="Lo" className="mainImageCenter" /></div>
                <div style={{display:"table", width:"90%", marginTop:"7vh"}}>
                    <div style={{display:"table-row", height:"1vh"}}>
                        <div style={{display:"table-cell", backgroundColor:"#996633"}}></div>
                        <div style={{display:"table-cell", backgroundColor:"#336699"}}></div>
                        <div style={{display:"table-cell", backgroundColor:"#FF9900"}}></div>
                        <div style={{display:"table-cell", backgroundColor:"#828200"}}></div>
                    </div>
                </div>
                <div className="mainLabelCenter">Loïe Valentine</div>
                <div className="mainSubLabelCenter"><a href="/garden">Adventures in Gardening</a></div>
                <div className="mainSubLabelCenter"><a href="/gallery">Handmade Ceramics</a></div>
            </div>
        );
    }
}