import React from 'react';

export default class ColoredTile extends React.Component {
    render() {
        let className = "coloredSquare";

        return (
            <div className={className} style={{backgroundColor: this.props.backColor}}>
                &nbsp;
            </div>
        );
    }
}