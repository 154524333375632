import React, {useState, useCallback} from 'react';
import { Alert, Image, Container, Row, Col, Badge } from 'react-bootstrap';
import {gardenItems} from './gardenItems'
import Carousel, { Modal, ModalGateway } from "react-images";

function PageGarden() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [currentImgView, setCurrentImgView] = useState([]);

    const openLightbox = useCallback((event) => {
        var newImgView = JSON.parse(event.target.dataset.imgs);
        setCurrentImgView(newImgView);
        setCurrentImage(event.target.dataset.index);
        setViewerIsOpen(true);
    }, []);
      
    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const getBadges = (item) => {
        var badges = [];

        if (item.native) {
            badges.push(<Badge key="0" className="gardenBadge" variant="secondary" title="Native to Georgia">Native</Badge>);
        }
       
        if (item.edible) {
            badges.push(<Badge key="1" className="gardenBadge" variant="success" title="Some or all parts edible">Edible</Badge>);
        }

        if (item.toxic) {
            badges.push(<Badge key="2" className="gardenBadge" variant="danger" title="Some or all parts are toxic">Toxic</Badge>);
        }

        return badges;
    }

    const gardenElements = gardenItems.map((item, index) =>  
        <div key={"item" + index} style={{marginRight:".25vw"}}>
            <Alert className="gardenItem">
                <Alert.Heading>
                    {item.heading}
                    <hr />
                </Alert.Heading>
                <Container>
                    <Row>
                        <Col sm={1}>
                            <div style={{display:"flex", flexDirection:"column", flexWrap:"wrap"}}>
                                {item.imgs.map((e, index) => {
                                        if (index === 0) {
                                            return null;
                                        }
                                        else {
                                            return (<Image key={index} data-index={index} 
                                                        data-imgs={JSON.stringify(item.imgs)}
                                                        className="gardenSubImage" onClick={openLightbox}
                                                        src={e.src} rounded fluid />);
                                        }
                                })}
                            </div>
                        </Col>
                        <Col sm={6}>
                            <picture>
                                <source srcSet={item.imgs[0].srcSet} />
                                <Image data-index={0} data-imgs={JSON.stringify(item.imgs)} className="gardenImage"
                                    src={item.imgs[0].src} onClick={openLightbox} rounded fluid />
                            </picture>
                            <br />
                            <Alert.Link className="text-muted" style={{fontSize:"small"}} href={item.wikiLink} target="_blank">
                                <em>{item.latinName}</em>
                            </Alert.Link>
                        </Col>
                        <Col sm={5} style={{textAlign: "left"}}>
                            <p>{item.body}</p>
                        </Col>
                    </Row>
                </Container>
                <hr />
                <div style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
                { getBadges(item) }
                </div>
            </Alert>
        </div>
    );

    return (
        <div style={{marginTop:"80px", marginLeft:"50px", marginRight:"50px", marginBottom:"150px"}}>
            {gardenElements}
            <ModalGateway>
                {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                    <Carousel
                        currentIndex={currentImage}
                        views={currentImgView.map(x => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.caption
                        }))}
                        styles={{
                            container: base => ({
                              ...base,
                              height: '100vh',
                            }),
                            view: base => ({
                              ...base,
                              alignItems: 'center',
                              display: 'flex ',
                              height: 'calc(100vh - 54px)',
                              justifyContent: 'center',
                              '& > img': {
                                maxHeight: 'calc(100vh - 94px)',
                              },
                            })}}
                        trackProps={
                            {
                                infinite: false
                            }}
                    />
                </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
}

export default PageGarden;
